import axios from 'axios';

const state={
    userInfo:{},
    fee:''
}
const getters ={
    getUserInfo(state){
        return state.userInfo;
    },
    getFees(state){

        return state.fee;
    }
}

const actions ={
    async getUserInfo({commit}){
        commit("setLoading", true);
        const res = await  axios.get("/users/account", {
            params: { imageNeeded: false },
        });
        commit('userInfoSet',res.baseDTO);
        let a;
        let accountLevel = await axios.get('/users/account-levels')
        a = accountLevel.find(i => i.userAccountLevel === res.baseDTO.userAccountLevel)
        let fee = a.wagePercent

        commit('setFee',fee)
        commit("setLoading", false);
    return res.baseDTO;
        },
}

const mutations ={
    userInfoSet(key,value){
        state.userInfo = value;
    },
    setFee(state,value){
        state.fee = value;
    }

}

export default {
    state,
    getters,
    actions,
    mutations,
};
