// import Vue from 'vue';
//
// const that = Vue.prototype;
import axios from 'axios'

const state = {
    ExCoins: '',
    ExCoin: '',
    ExPrice: 0,
    tether: false,
    networks:'',
    selectedNet:'',
}
const getters = {
    ExcoinGett(state){
        return state.ExCoin;

    },
    isExTether(state){
        return state.tether
    }
}

const actions = {
    async getExCoins({commit}, coin) {

        if (!coin) {
            coin = 'BITCOIN'
        }
        const res = await axios.get('/coins/details')
        const coins = res.content.filter(a => a.coin !== 'TOMAN')
        commit('setExCoins', coins);
        if (coin) {
            const selectedCoin = res.content.filter(i => i.coin === coin || i.code === coin);
            // console.log(selectedCoin[0].tokenTypeDetails);
           await commit('setExCoin', selectedCoin[0]);
            await commit('setNetworks',selectedCoin[0].tokenTypeDetails)
           // await commit('setDefNet',selectedCoin[0].tokenTypeDetails[0])
            return{
                selectedCoin,
                coins
            }

        }
    },

   async getExPrice({commit, state}, coin) {
       console.log('called')
        if(!coin){
            coin='BITCOIN'
        }
        let baseURL = state.tether ? '/coins/exchange-price/usdt' : '/coins/exchange-price/usd'
        const res =await axios.get(baseURL, {params: {includeWeeklyChange: false}});

        commit('setExPrice',res);
        return res;

        // console.log()
    },


    // getTetherPrice({commit},coin){
    //     const res= axios.get('/exchange/api/coins/exchange-price/usdt',{params:{includeWeeklyChange:false}})
    // }
}
const mutations = {
    setExCoins(state, value) {
        state.ExCoins = value
    },
    setExCoin(state, value) {
        state.ExCoin = value
    },
    setExPrice(state, value) {
        state.ExPrice = value;
    },
    setNetworks(state, value) {
        state.networks = value;
    },
    setSelectNet(state,value){
        state.selectedNet=value;
    },
    changeTether(state,value){
        state.tether = value;
    }

}
export default {
    state,
    getters,
    mutations,
    actions
}