

const state = {
    // persian: true,
}
const getters = {}
const actions ={}
const mutations ={
    // setLang(state, value) {
    //     state.persian = !state.persian;
    //     console.log(state.persian)
    //     value === false ? (i18n.locale = "en") : (i18n.locale = "fa");
    // },
}


export default{
    state,
    getters,
    actions,
    mutations
}