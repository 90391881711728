import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store/index.js'
import './global/globalConstants.js'
import './global/globalFunctions.js'
import './global/reuseableFunction.js'
import { BootstrapVue, IconsPlugin ,LayoutPlugin ,ModalPlugin ,CardPlugin,VBScrollspyPlugin , DropdownPlugin, TablePlugin} from 'bootstrap-vue'
import './plugin/axios.js'
import 'animate.css';
import i18n from './i18n'
import InlineSvg from 'vue-inline-svg';
import VueApexCharts from 'vue-apexcharts'
import './store/modules/state.js'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vmodal from 'vue-js-modal';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(vmodal)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)
Vue.use(VueSweetalert2);
Vue.use(VBScrollspyPlugin)
Vue.use(CardPlugin)
Vue.use(ModalPlugin)
Vue.use(LayoutPlugin)
Vue.config.productionTip = false
Vue.component('inline-svg', InlineSvg);
Vue.component('apexchart', VueApexCharts)


Vue.use(PerfectScrollbar)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
