const state={
    shopInfo:{
        maxBuy:'',
        minSell:''
    }
}
const getters ={
    maxBuy(){
        return state.shopInfo.maxBuy
    },
    minSell(){
        return state.shopInfo.minSell;
    }
}
const mutations={
    shopConfig(state,[key,value]){
        state.shopInfo[key]=value
        console.log(state.shopInfo)
    }
}
const actions ={}

export default {
    state,
    mutations,
    getters,
    actions,
}