import Vue from "vue";
import router from "@/router";
import axios from 'axios';
import './state'
import {store} from "@/store";
const that = Vue.prototype
const state = {
  coins: [],
  tomanCoins: [],
  tetherCoins: [],
  totalTradesVolume: "",
  token: "",
  // userInfo:{},
  gotten: "",
};
const getters = {
  tomanMarket(state) {
    return state.tomanCoins;
  },
  tetherMarket(state) {
    return state.tetherCoins;
  },
  coins(state){
    return state.coins;
  }
};

const actions = {


  async fetchCoins({ commit }) {
    // commit("loading", true);
    // const res = await axios.get('coins/exchange-price/usd?includeWeeklyChange=false')
    let res = await axios.get("coins/supporting-coins");
    console.log(res)
    that.$coins = { ...that.$coins, ...res };
    commit("setCoins", true);
    commit('supportingCoins',res)
    return res
    // commit("loading", false);


  },

  async logout({ commit, state }) {
    // commit("loading", true);

    localStorage.clear("token");
    Vue.$cookies.remove("--abcd14589");
    commit("setToken", "");
    commit("setGotten", false);
    axios.defaults.headers.common["Authorization"] = state.token;
    try {
      router.push("/login");
    } catch (e) {
      location.reload();
    }
    // commit("loading", false);
    // that.state.loading=false;
  },

  async setToken({ state, commit }, token, remember = true) {
    // commit("loading", true);
    if (remember) {
      localStorage.token = "Bearer " + token;
      commit("setToken", localStorage.token);
    } else {
      Vue.$cookies.set("--abcd14589", "Bearer " + token, "session");

      commit("setToken", Vue.$cookies.get("--abcd14589"));
    }
    commit("setGotten", false);
    axios.defaults.headers.common["Authorization"] = state.token;
  },





  // async getFee({ commit,state },userInfo) {
  //
  // },

  async fetchVol({ commit }) {
    const res = await axios("trades/market-info-home");
    let totalTradesVolume = res.baseDTO.tradesValue;
    commit("setVol", totalTradesVolume);
  },
};

const mutations = {

//   setUser(state,[key,value]){
//       state.userInfo[key] = value;
// // console.log(state.userInfo)
//   },
//   userDetail(state,value,key) {
//     state.userInfo[key] = value;
//
//   },
//   setUsers(state,obj){
//     let test = Object.entries(obj)
//     test.map(item => {
//       state.userInfo[item[0]]=item[1]
//     })
//     // console.log(state.userInfo)
//   },



  setLoading(state, value) {
    state.loading = value;
    // value ===true ? console.log('true') : console.log('false')
  },
supportingCoins(state,value){
  state.coins=value;
},
  setCoins(state, value) {
    state.coins = value;
  },
  setTomanCoins(state, value) {
    state.tomanCoins = value;
  },
  setTetherCoins(state, value) {
    state.tetherCoins = value;
  },

  setToken(state, value) {
    state.token = value;
  },
  setGotten(state, value) {
    state.gotten = value;
  },
  setVol(state, value) {
    state.totalTradesVolume = value;
  },

};



axios.interceptors.response.use(
    (res) => {
      that.state.loading=false;
      store.commit('setLoading', false);
      return res.data;
    },
    (err) => {
      that.state.loading=false;
store.commit('setLoading', false);
      const res = err.response || err.toJSON();
      // console.log(err.response.data);
      if (res.data.message.includes("429")) {
        return Promise.reject(err);
      }

      if (res.config.responseType === "blob") {
        const vm = that;
        let reader = new FileReader();
        reader.onload = function (e) {
          let test = JSON.parse(e.target.result);
          store.state.lang === "fa"
              ? vm.$error("", vm.$errorHandeling[test.message])
              : vm.$error("", this.message);
        };
        reader.readAsText(err.response.data);
        return Promise.reject(err);
      }

      if (err.response)
        if (res.data.message)
          if (
              res.data.message.includes("JWT") ||
              res.data.message.includes("Invalid Token")
          ) {
            //jwt errors
            store.dispatch('logout');
            return Promise.reject(err);
          } else if (err.response.data.message.includes("ShebaCode")) {
            store.state.lang !== "fa"
                ? that.$error("Duplicated card", "")
                : that.$error("شماره شبا یا کارت تکراری است.", "");
            return Promise.reject(err);
          } else if (
              ["GOOGLE_FAILURE", "Can't Access Resource"].includes(
                  err.response.data.message
              )
          ) {
            return Promise.reject(err);
            // user identity
          } else if (err.response.data.message === "USER_IDENTITY_DATA") {
            // console.log('in USER_IDENTITY_DATA');

            // mobile number
            const a = {
              MOBILE_IS_INCORRECT: "شماره همراه تکراری میباشد.",
              NATIONAL_CODE_IS_INCORRECT: "کد ملی تکراری میباشد.",
              TELEPHONE_IS_INCORRECT: "شماره تلفن تکراری میباشد.",
            };
            let uniqueErrors = "";
            for (let i = 0; i < err.response.data.errors.length; i++) {
              if (store.state.lang == "fa") {
                uniqueErrors =
                    uniqueErrors +
                    (a[err.response.data.errors[i]] ||
                        err.response.data.errors[i]) +
                    "\n";
              } else
                uniqueErrors =
                    uniqueErrors +
                    (err.response.data.errors[i] || err.response.data.errors[i]) +
                    "\n";
            }
            //showing modal
            store.state.lang !== "fa"
                ? that.$error("Duplicated information", uniqueErrors)
                : that.$error("اطلاعات تکراری میباشد", uniqueErrors);
            return Promise.reject(err);

            //bad request handling
          } else if (err.response.data.status === "BAD_REQUEST") {
            let a = err.response.data.errors[0];
            // a = JSON.parse(a.match(/\[(.*?)\]/)[1])

            //finotech errors
            const codeError = {
              "invalid otp or mobile": "کد وارد شده اشتباه است",
              "otp used before": "کد منقضی شده است",
            };
            const finoError = {
              "nid is not valid": "کد ملی صحیح نیست",
              "mobile is not valid": "شماره همراه صحیح نیست",
              "nid and mobile is not match":
                  "شماره همراه شما با کد ملی مطابقت ندارد",
              "هیچ رکوردی یافت نشد. لطفا مقادیر ورودی را کنترل نمایید":
                  "تاریخ تولد با سایر اطلاعات همخوانی ندارد.",
            };
            if (store.state.lang == "fa") {
              if (finoError[a] || codeError[a]) {
                that.$error("خطای احراز هویت", codeError[a] || finoError[a]);
                if (finoError[a]) {
                  router.push({ name: "Account" });
                }
                return Promise.reject(err);
              } else if (store.state.lang == "en") {
                if (a) {
                  that.$error("Authentication Error", a);
                  router.push({ name: "Account" });
                }
                return Promise.reject(err);
              }
            }
          }

      // if (err.response ? ['UNAUTHORIZED'].includes(res.data.status) : false) {
      //     return Promise.reject(err);
      // }

      let text = err.response
          ? that.$errorHandeling[err.response.data.message] ||
          that.$errorHandeling[err.response.data.status] ||
          res.data.errors ||
          res.data.error
          : res.message;
      if (store.state.lang == "en") {
        text = err.response
            ? err.response.data.message ||
            err.response.data.status ||
            res.data.errors ||
            res.data.error
            : res.message;
      }

      if (
          (err.response && that.$errorHandeling[err.response.data.message]) ||
          that.$errorHandeling[err.response.data.status]
      ) {
        that.$error(err.request.status, text);
      }

      return Promise.reject(err);
    }
);


export default {
  state,
  getters,
  actions,
  mutations,
};
