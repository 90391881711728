<template>
<div class="modal-backdrop">

    <div class="modal">
 <svg viewBox="0 0 100 100" style="width: 100px; height: 100px; margin: 0 auto;"   >     </svg>
      <svg class="first" viewBox="0 0 100 100" style="width: 200px; height: 200px; margin: 0 auto;">
        <defs>
          <linearGradient id="Gradient" x1="50%" y1="0%" x2="50%" y2="100%" >

            <stop offset="0%" stop-color="#E323FF">
              <animate attributeName="stop-color" values="#E323FF; #E323FF" dur="4s" repeatCount="indefinite"></animate>
            </stop>

            <stop offset="100%" stop-color="#01FF89">
              <animate attributeName="stop-color" values="#01FF89; #7A5FFF; #01FF89" dur="4s" repeatCount="indefinite"></animate>
            </stop>

          </linearGradient>
        </defs>
        <circle class="circle2" cx="50" cy="50" r="40" fill="none"></circle>
        <circle  cx="50" cy="50" r="20" fill="rgba(55, 0, 107, 0.58)"></circle>
      </svg>
      <svg class="second" viewBox="0 0 100 100" style="width: 200px; height: 200px; margin: 0 auto;">
        <defs>
          <linearGradient id="Gradient2" x1="50%" y1="0%" x2="50%" y2="100%" >

            <stop offset="0%" stop-color="#FFD422">
              <animate attributeName="stop-color" values="#FFD422; #FF7D05
" dur="4s" repeatCount="indefinite"></animate>
            </stop>

            <stop offset="100%" stop-color="#4DA1FF">
              <animate attributeName="stop-color" values="#4DFFDF; #4DA1FF;" dur="4s" repeatCount="indefinite"></animate>
            </stop>

          </linearGradient>
        </defs>
        <circle class="circle" cx="50" cy="50" r="30" fill="none"></circle>
      </svg>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.modal-backdrop{
  opacity: 1 !important;
}
.first,.second{
  position:absolute;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom:0;
  right:0;
  left:0;
}
.modal{
  position: relative;
}

.circle {

  animation: 8s loader ease infinite alternate,  2s loader2 linear infinite;
  stroke: url(#Gradient);
  stroke-linecap: round;
  stroke-width: 5;
  stroke-dasharray: 94.0955352783 94.0955352783;
  stroke-dashoffset: 0;
}










@keyframes loader {
  0% {
    stroke-dasharray: 84.0955352783 104.0955352783;
  }
  100% {
    stroke-dasharray: 178.19107055664062 10;
  }
}
@keyframes loader2 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -188.19107055664062;
  }
}

.circle2 {
  animation: 3s loader3 ease infinite alternate, 5s loader4 linear infinite;
  stroke: url(#Gradient2);
  stroke-linecap: round;
  stroke-width: 5;
  stroke-dasharray: 90 90;
  stroke-dashoffset: 0;
}

@keyframes loader3 {
  0% {

    stroke-dasharray: 40 40;
  }
  100% {
    stroke-dasharray:130 2;
  }
}
@keyframes loader4 {
  0% {
         stroke-dashoffset:0;
  }
  100% {
      stroke-dashoffset: -80 ;
  }
}



#Progress-1{
animation:  spin 800ms ease-in-out infinite;
}

@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
    }
    }

</style>