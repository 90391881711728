import Vue from "vue";
import {store} from '../store/index.js'
import axios from "axios";




const that = Vue.prototype;




//
if (store.state.token) {
    // console.log('I have token : ', store.state.token)
    axios.defaults.headers.common["Authorization"] = store.state.token;

}


// axios.defaults.baseURL = 'https://beebitex-1.com/api'
// axios.defaults.baseURL = "https://vistadev.xyz/exchange/api";
axios.defaults.baseURL = "https://tiirex.website/exchange/api";
// axios.defaults.baseURL = "https://safexexchange.com/api";
// axios.defaults.baseURL = process.env.VUE_APP_AXIOS

if (process.env.NODE_ENV === "development") {
    // axios.defaults.baseURL = 'https://hakarex.net/api'
}



let func = async (config) => {
    try {
        const res = await axios(config);
        return [res, null];
    } catch (e) {
        return [null, e];
    }
};
const props = {
    get: async (url, config) => {
        try {
            const res = await axios.get(url, config);
            return [res, null];
        } catch (e) {
            return [null, e];
        }
    },
    post: async (url, data, config) => {
        try {
            const res = await axios.post(url, data, config);
            return [res, null];
        } catch (e) {
            return [null, e];
        }
    },
    put: async (url, data, config) => {
        try {
            const res = await axios.put(url, data, config);
            return [res, null];
        } catch (e) {
            return [null, e];
        }
    },
    delete: async (url, config = {}) => {
        try {
            const res = await axios.delete(url, config);
            return [res, null];
        } catch (e) {
            return [null, e];
        }
    },
};
for (let prop in props) {
    func[prop] = props[prop];
}

that.$http = func;
that.$axios = axios;

export const http = func;
export default axios;
