import Vue from "vue";

const that = Vue.prototype

const state = Vue.observable({
    loading : false,
})

that.state = state

export default state;