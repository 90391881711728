<template>
  <div id="app" :class="{'rtl':$store.state.persian , 'ltr':!$store.state.persian}">
<LoadingComponent v-if="$store.state.loading"/>
    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <router-view />
    </transition>
  </div>
</template>

<style lang="scss">
@import "./assets/style/bootstrap-grid.css";
@import "./assets/style/inputs.scss";
@import "./assets/style/index.scss";
</style>
<script>
import LoadingComponent from "./components/LoadingComponent.vue";
import axios from "axios";

export default {
  data(){
    return{
    }
  },
  computed:{
    loading(){
      return this.$store.state.loading
    }
  },


    methods: {
        // ...mapActions(["fetchCoins"])
    },
    mounted() {

        this.$store.dispatch('fetchCoins')
 this.$store.dispatch('setToken',this.$store.state.token,true)
this.$store.dispatch('getUserInfo')
      // this.$store.dispatch('getFee')
    },
    components: { LoadingComponent }
};
</script>
<style scoped>
#app{
  background-color: var(--bg);
}
@font-face {
  font-family: 'IRANSans';
  src: url('@/assets/style/VistaDev.ttf') format('truetype');
}
@font-face {
  font-family: 'IRANSans_en';
  src: url('@/assets/style/IRANSansWeb.ttf') format('truetype');
}



.rtl{
  direction: rtl !important;
  font-family: IRANSans !important;
}
.ltr{
  direction: ltr !important;
  font-family: IRANSans_en;
}
</style>
