import Vue from "vue";
import Vuex from "vuex";
import i18n from "../i18n";
import userInfo from  './modules/userInfo.js'
import global from './modules/globalActions'
import language from './modules/language'
import shopInfo from "@/store/modules/shopInfo";
import OTC from './modules/OTC'
Vue.use(Vuex);


export const store = new Vuex.Store({
    state: {
        persian: true,
        exchangeBuyPrice: 0,
        exchangeSellPrice: 0,
        coins: false,
        transferFee: 0,
        orderChange: 0,
        tether:false,
        // token: localStorage.token || Vue.$cookies.get("--abcd14589"),
        token:'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqb2RhdHBhbmFoMkBnbWFpbC5jb20iLCJpYXQiOjE2NTQ1Nzk5MjEsImV4cCI6MTY4NjExNTkyMX0.BLN9uIsQiemluwICs_DdOD5-Hzr_8Cv98vu7e0LTIpc',
        loading: false,
        gotten: false,
        loop: true,
        reload: 0,
        progress: 0,
        totalTradesVolume: 0,
        towStepAuthenticationType: "",
        userFee: 0,
        // userInfo: {},
        shopInfo: {
            minSell: 0,
            maxBuy: 0,
            lastPrice: 0,
        },
        buyAmount: 0,
        buyPrice: 0,
        sellAmount: 0,
        sellPrice: 0,
        fee:0,
    },
    
    getters: {


    },
    mutations: {
       // function that changes language
        setLang(state, value) {
            state.persian = !state.persian;
            console.log(state.persian)
            value === false ? (i18n.locale = "en") : (i18n.locale = "fa");
        },


 
        setVol(state, value) {
            state.totalTradesVolume = value;
        },
        setSell(state, value) {
            state.shopInfo.minSell = value;
        },

        settBuy(state, value) {
            state.shopInfo.maxBuy = value;
        },

        setLastPrice(state, value) {
            state.shopInfo.lastPrice = value;
        },
        setChange24(state, value) {
            state.shopInfo.change24 = value;
        },
        // setFee(state, value) {
        //     state.fee = value;
        // },

    },
    actions: {


    },
    modules: {
        global,
        language,
        userInfo,
        OTC,
        shopInfo
    },
});
